// TODO: deprecate in favor of views
export const routes = {
    address: (profile) => `/${profile}/address/`,
    connect: (profile, app) => `/${profile}/${app}/settings`,
    details: (profile) => `/${profile}/details/`,
    emarketingDetail: (profile, id) => `/${profile}/emarketing/post/${id}`,
    emarketingReport: (profile, id) => `/${profile}/reports/emarketing/${id}`,
    personal_details: (profile) => `/${profile}/personal_details/`,
    plan: (profile) => `/${profile}/plan?refresh=true`,
    removeApp: (profile, app) => `/${profile}/${app}/delete`,
    upgrade: (profile, perm, next) => `/${profile}/plan?perm=${perm}&redirect=${next}&refresh=true`,
};

const getProfileCalendarView = (profileSlug, dateTime=null, ids=null) => {
    const dateTimeParam = dateTime ? `?date=${dateTime}` : '?date=';
    const idsParam = ids ? `&ids=${ids}` : '';
    const allParams = [dateTimeParam, idsParam].filter(p => p).join('')
    return `/${profileSlug}/calendar/${allParams}`;
}

export const views = {
    // django view name => url
    app_settings: (profileSlug, app) => {
        return `/${profileSlug}/${app}/settings/`;
    },

    emarketing_post: profileSlug => `/${profileSlug}/emarketing/post/`,
    emarketing_reports_detail: (scheduledEmailCampaignId, profileSlug) => {
        return `/${profileSlug}/reports/emarketing/${scheduledEmailCampaignId}/`;
    },
    emarketing_scheduled_email_campaign: (scheduledEmailCampaignId, profileSlug) => {
        return `/${profileSlug}/emarketing/post/${scheduledEmailCampaignId}/`;
    },
    profile_plan: profileSlug => `/${profileSlug}/plan?refresh=true`,
    seller_details: (profile) => `/${profile}/seller/`,
    profile_calendar: getProfileCalendarView,

    facebook_welcome: profileSlug => `/${profileSlug}/facebook/welcome/`,
    facebook_delete: profileSlug => `/${profileSlug}/facebook/delete/`,
    instagram_welcome: profileSlug => `/${profileSlug}/instagram/welcome/`,
    instagram_delete: profileSlug => `/${profileSlug}/instagram/delete/`,
    linkedin_welcome: profileSlug => `/${profileSlug}/linkedin/welcome/`,
    linkedin_delete: profileSlug => `/${profileSlug}/linkedin/delete/`,
    emarketing_welcome: profileSlug => `/${profileSlug}/emarketing/welcome/`,
    emarketing_signup: profileSlug => `/${profileSlug}/emarketing/signup/`,
    emarketing_signature: profileSlug => `/${profileSlug}/emarketing/settings/`,
    emarketing_contact_list: profileSlug => `/${profileSlug}/emarketing/contact-list/primary`,
    emarketing_delete: profileSlug => `/${profileSlug}/emarketing/delete/`,
};
