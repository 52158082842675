<template>
    <div>
        <section class="row" v-cloak>
            <div class="col-lg-7">
                <div class="d-lg-none d-block">
                    <div class="mt-3">
                        <div class="d-flex justify-content-between">
                            <generated-post-create-automatic
                                :label="$t('post.actions.postNewAutomatic')"
                                :okCallback="refreshList"
                                :endpoint="generatorProfileCreateEndpoint">
                            </generated-post-create-automatic>

                            <b-button variant="outline-dark" v-b-modal.generator-create-modal class="mb-3">
                                <i class="fa fa-pencil"></i>
                                {{ $t("post.actions.postNew") }}
                            </b-button>

                            <b-button variant="outline-dark" v-b-modal.generator-settings :disabled="creating_post" class="mb-3">
                                <i class="fa fa-cog"></i>
                                AI settings
                            </b-button>
                        </div>
                    </div>
                </div>

                <generated-post-list ref="generatedPostList">
                </generated-post-list>
            </div>

            <div class="col-lg-5 d-none d-lg-block">
                <generated-post-create-automatic
                    :okCallback="refreshList"
                    :endpoint="generatorProfileCreateEndpoint">
                </generated-post-create-automatic><br/>

                <b-button variant="outline-dark" v-b-modal.generator-create-modal class="mb-2 w-100">
                    <i class="fa fa-pencil"></i>
                    {{ $t("post.actions.postNew") }}
                </b-button><br/>

                <b-button variant="outline-dark" v-b-modal.generator-settings :disabled="creating_post" class="mb-2 w-100">
                    <i class="fa fa-cog"></i>
                    AI settings
                </b-button>
            </div>
        </section>

        <generated-post-create-modal
            id="generator-create-modal"
            :endpoint="generatorProfileCreateEndpoint"
            :okCallback="refreshList">
        </generated-post-create-modal>

        <generator-profile-settings id="generator-settings" @update="$bvModal.hide('generator-settings')"></generator-profile-settings>
    </div>
</template>

<script>
    import { endpoints } from './api/EndpointsConfig.js'
    import GeneratedPostCreateAutomatic from './generated_post/GeneratedPostCreateAutomatic.vue';
    import GeneratorProfileSettings from './generator_profile/GeneratorProfileSettings.vue';
    import GeneratedPostList from './generated_post/GeneratedPostList.vue';
    import GeneratedPostCreateModal from './generated_post/GeneratedPostCreateModal.vue';

    export default {
        name: 'GeneratorMain',
        components: {
            GeneratedPostList,
            GeneratorProfileSettings,
            GeneratedPostCreateModal,
            GeneratedPostCreateAutomatic,
        },
        data() {
            return {
                generatorProfileCreateEndpoint: endpoints.generator_profile_create(window.profileData.pk),
                creating_post: false,
            };
        },
        methods: {
            refreshList() {
                this.$refs.generatedPostList.loadItems();
            },
        }
    }
</script>
