import { formatDate } from '@/utils/datetime.js';

const getScheduledEmailCampaignListAPI = (emarketingId, fireAtFrom=null, fireAtTo=null, queryType=null) => {
    const fromParam = fireAtFrom ? `&fire_at_from=${formatDate.longISO(fireAtFrom)}` : '';
    const toParam = fireAtTo ? `&fire_at_to=${formatDate.longISO(fireAtTo)}` : '';
    const queryTypeParam = queryType ? `&query_type=${queryType}` : ''
    const allParams = [fromParam, toParam, queryTypeParam].filter(p => p).join('')
    return `/api/scheduled_email_campaign/?emarketing=${emarketingId}${allParams}`;
}

const getScheduledPostingListAPI = (pageId, provider=null, status=null, deleted=null, fireAtFrom=null, fireAtTo=null, createdFrom=null, createdTo=null, top=null, ordering=null, page=null) => {
    const providerParam = provider !== null ? `&provider=${provider}` : '';
    const statusParam = status !== null ? `&status=${status}` : '';
    const fromParam = fireAtFrom ? `&fire_at_from=${formatDate.longISO(fireAtFrom)}` : '';
    const toParam = fireAtTo ? `&fire_at_to=${formatDate.longISO(fireAtTo)}` : '';
    const deletedParam = deleted !== null ? `&deleted=${deleted}` : '';
    const orderingParam = ordering && !top ? `&ordering=${ordering}` : '';
    const createdFromParam = createdFrom ? `&created_from=${formatDate.longISO(createdFrom)}` : '';
    const createdToParam = createdTo ? `&created_to=${formatDate.longISO(createdTo)}` : '';
    const topParam = top ? `&top=${top}` : '';
    const pageParam = page ? `&page=${page}` : '';
    const allParams = [providerParam, statusParam, deletedParam, fromParam, toParam, createdFromParam, createdToParam, topParam, orderingParam, pageParam].filter(p => p).join('')
    return `/api/scheduled_postings/?pgid=${pageId}${allParams}`;
}

const getPostLibraryListAPI = (profileId, search=null, campaigns=null) => {
    const searchParam = search !== null ? `&search=${search}` : '';
    const campaignsParam = campaigns ? '&' + campaigns.map((id) => `campaign=${id}`).join('&') : '';
    const allParams = [searchParam, campaignsParam].filter(p => p).join('')
    return `/api/post_library/?profile=${profileId}${allParams}`;
}

export const endpoints = {
    'profilesocial-status': (profileId, appSlug) => `/api/profile_social/${profileId}/${appSlug}/status/`,
    'profilesocial-accountdata': (profileId, appSlug) => `/api/profile_social/${profileId}/${appSlug}/account_data/`,
    'profilesocial-url': (profileId, appSlug) => `/api/profile_social/${profileId}/${appSlug}/url/`,
    'profilesocial-pages': (profileId, appSlug) => `/api/profile_social/${profileId}/${appSlug}/pages/`,
    'profilesocial-select': (profileId, appSlug, pageId) => {
        return `/api/profile_social/${profileId}/${appSlug}/select/?id=${pageId}`;
    },
    'post-library': getPostLibraryListAPI,
    'post-library-schedule': (profileId, postId) => `/api/post_library/${postId}/schedule/?profile=${profileId}`,
    'campaign-library': (profile_pk) => `/api/campaign_library/?profile=${profile_pk}`,
    'scheduledemailcampaign-list': getScheduledEmailCampaignListAPI,
    'scheduledposting-list': getScheduledPostingListAPI,
    'announcements_list': (profile_pk) => `/api/announcements/?profile=${profile_pk}`,
    'onboarding_videos_list': (profile_pk) => `/api/onboarding_videos/?profile=${profile_pk}`,
    'superuser-downgrade': (userId) => `/api/users/${userId}/downgrade/`,
};
